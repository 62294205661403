import styled from 'styled-components'

const Card = styled.article`
    position: relative;
    display: flex;
    flex-direction: column;
    //box-shadow: 0 0 3px rgba(0, 0, 0, .125);
    // transition: 0.2s ease-in-out;
    border-bottom: solid 2px #ebebeb;
    margin-top: 3px;

    :hover, :focus-within {
       transform: translateY(-3px);
       box-shadow: 0 0 3px rgba(0, 0, 0, .125);
    }

    p {
        flex-grow: 1;
    }
`

export default Card